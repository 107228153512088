<template>
  <div>
    <b-row>
      <b-col sm="12">
        <b-table class="mb-0"
                 responsive="sm"
                 head-variant="dark"
                 :hover="true"
                 :small="true"
                 :fixed="false"
                 :items="table.items"
                 :fields="table.fields"
                 :filter="table.filter"
                 :current-page="table.currentPage"
                 :per-page="table.perPage"
                 v-if="table.items.length">

          <template v-slot:cell(id)="data">
            <b>{{ data.item.id }}</b>
          </template>

          <template v-slot:cell(reference)="data">
            <b v-if="data.item.reference == 'customers'">Clientes</b>
            <b v-if="data.item.reference == 'suppliers'">Proveedores</b>
            <b v-if="data.item.reference == 'products'">Productos</b>
            <b v-if="data.item.reference == 'iva'">IVA</b>
            <b v-if="data.item.reference == 'perceptions'">Percepciones</b>
            <b v-if="data.item.reference == 'services'">Servicios</b>
            <b v-if="data.item.reference == 'cash'">Cajas/Cuentas</b>
            <b v-if="data.item.reference == 'valores'">Cartera de Valores</b>
          </template>

          <template v-slot:cell(accounting_accounts_id)="data">
            <b-badge variant="secondary" v-if="data.item.accounting_account" class="accounting-settings-account-selected">
              {{ data.item.accounting_account.name }}
            </b-badge>
          </template>

          <template v-slot:cell(accounting_accounts_secondary_id)="data">
            <b-badge variant="secondary" v-if="data.item.accounting_account_secondary" class="accounting-settings-account-selected">
              {{ data.item.accounting_account_secondary.name }}
            </b-badge>
          </template>

          <template v-slot:cell(f_action)="data">
            <b-dropdown right text="Acción" size="sm" variant="outline-dark" class="pull-right">
              <b-dropdown-header>Acciones</b-dropdown-header> 
              <b-dropdown-item @click="edit(data.item)">
                <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
              </b-dropdown-item>
            </b-dropdown>
          </template>
      </b-table>
      <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
    </b-col>       
  </b-row>
            
  <b-row class="mt-2">
    <b-col>
      <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
        <i class="fa fa-angle-double-left"></i>
        Volver
      </b-button>                        
    </b-col>        
  </b-row>
      
  <!-- ########################### -->
  <!-- #####     MODALES     ##### -->
  <!-- ########################### -->

  <!-- CRUD MAIN -->
  <b-modal v-model="modal.form.active"
            header-bg-variant="dark"
            header-text-variant="white">
      <div slot="modal-header">
          {{this.modal.form.title}}
      </div>

      <b-row>
        <b-col md="12">
          <!-- CLIENTES -->
          <FindObject render="search"
                      tag="Cuenta Contable de Activo"
                      type="accountingAccountsImputation" 
                      @select-object="loadAccountingAccounts($event)" 
                      :valueID="crud.form.accounting_accounts_id"
                      :where="conditionAccountingAccountsCustomers"
                      v-if="crud.form.reference == 'customers'"/>

          <FindObject render="search"
                      tag="Cuenta Contable de Ingreso"
                      type="accountingAccountsImputation" 
                      @select-object="loadAccountingAccountsSecondary($event)" 
                      :valueID="crud.form.accounting_accounts_secondary_id"
                      :where="conditionAccountingAccountsSecondaryCustomers"
                      v-if="crud.form.reference == 'customers'"/>

          <!-- PROVEEDORES -->
          <FindObject render="search"
                      tag="Cuenta Contable de Pasivo"
                      type="accountingAccountsImputation" 
                      @select-object="loadAccountingAccounts($event)" 
                      :valueID="crud.form.accounting_accounts_id"
                      :where="conditionAccountingAccountsSuppliers"
                      v-if="crud.form.reference == 'suppliers'"/>

          <FindObject render="search"
                      tag="Cuenta Contable de Gasto"
                      type="accountingAccountsImputation" 
                      @select-object="loadAccountingAccountsSecondary($event)" 
                      :valueID="crud.form.accounting_accounts_secondary_id"
                      :where="conditionAccountingAccountsSecondarySuppliers"
                      v-if="crud.form.reference == 'suppliers'"/>

          <!-- PRODUCTOS -->
          <FindObject render="search"
                      type="accountingAccountsImputation" 
                      @select-object="loadAccountingAccounts($event)" 
                      :valueID="crud.form.accounting_accounts_id"
                      :where="conditionAccountingAccountsProducts"
                      v-if="crud.form.reference == 'products'"/>

          <!-- IVA -->
          <FindObject render="search"
                      tag="Cuenta Contable de Venta"
                      type="accountingAccountsImputation" 
                      @select-object="loadAccountingAccounts($event)" 
                      :valueID="crud.form.accounting_accounts_id"
                      :where="conditionAccountingAccountsIva"
                      v-if="crud.form.reference == 'iva'"/>

          <FindObject render="search"
                      tag="Cuenta Contable de Compra"
                      type="accountingAccountsImputation" 
                      @select-object="loadAccountingAccountsSecondary($event)" 
                      :valueID="crud.form.accounting_accounts_secondary_id"
                      :where="conditionAccountingAccountsSecondaryIva"
                      v-if="crud.form.reference == 'iva'"/>

          <!-- PERCEPCIONES -->
          <FindObject render="search"
                      tag="Cuenta Contable de Venta"
                      type="accountingAccountsImputation" 
                      @select-object="loadAccountingAccounts($event)" 
                      :valueID="crud.form.accounting_accounts_id"
                      :where="conditionAccountingAccountsPerceptions"
                      v-if="crud.form.reference == 'perceptions'"/>

          <FindObject render="search"
                      tag="Cuenta Contable de Compra"
                      type="accountingAccountsImputation" 
                      @select-object="loadAccountingAccountsSecondary($event)" 
                      :valueID="crud.form.accounting_accounts_secondary_id"
                      :where="conditionAccountingAccountsSecondaryPerceptions"
                      v-if="crud.form.reference == 'perceptions'"/>

          <!-- SERVICIOS -->
          <FindObject render="search"
                      type="accountingAccountsImputation" 
                      @select-object="loadAccountingAccounts($event)" 
                      :valueID="crud.form.accounting_accounts_id"
                      :where="conditionAccountingAccountsServices"
                      v-if="crud.form.reference == 'services'"/>

          <!-- CAJAS/CUENTAS -->
          <FindObject render="search"
                      type="accountingAccountsImputation" 
                      @select-object="loadAccountingAccounts($event)" 
                      :valueID="crud.form.accounting_accounts_id"
                      :where="conditionAccountingAccountsCash"
                      v-if="crud.form.reference == 'cash'"/>                      

          <!-- CARTERA DE VALORES -->
          <FindObject render="search"
                      type="accountingAccountsImputation" 
                      @select-object="loadAccountingAccounts($event)" 
                      :valueID="crud.form.accounting_accounts_id"
                      :where="conditionAccountingAccountsValores"
                      v-if="crud.form.reference == 'valores'"/>                                            
        </b-col>
      </b-row>

      <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.form.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="save()">Guardar</b-button>          
      </div>
    </b-modal>
  </div>
</template>

<script>
  import serviceAPI from './../services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import FindObject from '@/components/inc/find/findObject'  

  export default {
    components: {
      FindObject,        
    },
    data: () => {
      return {
        table : {
          items: [],
          fields: [
            {key: 'id', label: 'ID', sortable: true},
            {key: 'reference', label: 'Nombre', class:"align-middle"},
            {key: 'accounting_accounts_id', label: 'Cuenta Contable', class:"align-middle"},
            {key: 'accounting_accounts_secondary_id', label: 'Cuenta Contable', class:"align-middle"},
            {key: 'f_action', label:'', class:"align-middle"},
          ],
        },
        crud: {
          form: {
            id: 0,
            reference: '',
            accounting_accounts_id: [],
            accounting_accounts_secondary_id: [],
          }, 
        },
        modal: {
          form: {
            active: false,
            title: ''
          },       
        },
      }
    },
    computed: {
      // CONDITIONS SELECT CLIENTES
      conditionAccountingAccountsCustomers(){
        return [
          {field: 'active', condition: true},
          {field: 'type', condition: 'Activo'}
        ];
      },  
      conditionAccountingAccountsSecondaryCustomers(){
        return [
          {field: 'active', condition: true},
          {field: 'type', condition: 'Ingresos'}
        ];
      },  
      // CONDITIONS SELECT PROVEEDORES
      conditionAccountingAccountsSuppliers(){
        return [
          {field: 'active', condition: true},
          {field: 'type', condition: 'Pasivo'}
        ];
      },  
      conditionAccountingAccountsSecondarySuppliers(){
        return [
          {field: 'active', condition: true},
          {field: 'type', condition: 'Egresos'}
        ];
      },  
      // CONDITIONS SELECT PRODUCTOS
      conditionAccountingAccountsProducts(){
        return [
          {field: 'active', condition: true},
          {field: 'type', condition: 'Ingresos'}
        ];
      }, 
      // CONDITIONS SELECT IVA
      conditionAccountingAccountsIva(){
        return [
          {field: 'active', condition: true},
          {field: 'type', condition: 'Pasivo'}
        ];
      },  
      conditionAccountingAccountsSecondaryIva(){
        return [
          {field: 'active', condition: true},
          {field: 'type', condition: 'Activo'}
        ];
      }, 
      // CONDITIONS SELECT PERCEPCIONES
      conditionAccountingAccountsPerceptions(){
        return [
          {field: 'active', condition: true},
          {field: 'type', condition: 'Pasivo'}
        ];
      },  
      conditionAccountingAccountsSecondaryPerceptions(){
        return [
          {field: 'active', condition: true},
          {field: 'type', condition: 'Activo'}
        ];
      },  
      // CONDITIONS SELECT SERVICIOS
      conditionAccountingAccountsServices(){
        return [
          {field: 'active', condition: true},
          {field: 'type', condition: 'Ingresos'}
        ];
      }, 
      // CONDITIONS SELECT CAJAS/CUENTAS
      conditionAccountingAccountsCash(){
        return [
          {field: 'active', condition: true},
          {field: 'type', condition: 'Activo'}
        ];
      }, 
      // CONDITIONS SELECT VALORES
      conditionAccountingAccountsValores(){
        return [
          {field: 'active', condition: true},
          {field: 'type', condition: 'Activo'}
        ];
      },             
    },
    mounted() {
      this.load()
    },
    methods: {
      load() {
        var result = serviceAPI.obtenerCuentaContableSettings()

        result.then((response) => {
          var data = response.data
          this.table.items = data
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error))
        });        
      },
      edit(item) {                
        this.crud.form.id = item.id
        this.crud.form.reference = item.reference
        this.crud.form.accounting_accounts_id = item.accounting_accounts_id
        this.crud.form.accounting_accounts_secondary_id = item.accounting_accounts_secondary_id
        
        if(item.reference == 'customers'){
          this.modal.form.title = "Editar cuenta contable de clientes"
        } else if (item.reference == 'suppliers'){
          this.modal.form.title = "Editar cuenta contable de proveedores"
        } else if (item.reference == 'products'){
          this.modal.form.title = "Editar cuenta contable de productos"
        } else if (item.reference == 'iva'){
          this.modal.form.title = "Editar cuenta contable de iva"
        } else if (item.reference == 'perceptions'){
          this.modal.form.title = "Editar cuenta contable de percepciones"
        } else if (item.reference == 'services'){
          this.modal.form.title = "Editar cuenta contable de servicios"
        } else if (item.reference == 'cash'){
          this.modal.form.title = "Editar cuenta contable de caja/cuenta"
        } else if (item.reference == 'valores'){
          this.modal.form.title = "Editar cuenta contable de cartera de valores"
        }

        this.modal.form.active = true
      },
      save() {        
        let loader = this.$loading.show();
        
        var result = serviceAPI.editarCuentaContableSettings(this.crud.form);                

        result.then((response) => {
          this.modal.form.active = false
          loader.hide()
          this.load()
          this.$awn.success("Datos guardados con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },
      loadAccountingAccounts (object) {
        if(object){
          this.crud.form.accounting_accounts = object
          this.crud.form.accounting_accounts_id = object.id             
        } else {
          this.crud.form.accounting_accounts = null
          this.crud.form.accounting_accounts_id = 0
        }
      },   
      loadAccountingAccountsSecondary (object) {
        if(object){
          this.crud.form.accounting_accounts_secondary = object
          this.crud.form.accounting_accounts_secondary_id = object.id             
        } else {
          this.crud.form.accounting_accounts_secondary = null
          this.crud.form.accounting_accounts_secondary_id = 0
        }
      },   
    }
  }
</script>
<style scoped>
  .accounting-settings-account-selected {
    font-size: 13px !important;
  }
</style>